// export const API_BASE_URL = "https://cors-anywhere.herokuapp.com/http://193.56.59.51:50003";

// export const API_BASE_URL = "http://193.56.59.51:50003";
// export const API_BASE_IMG = "http://193.56.59.51:50005";

// export const API_BASE_URL = "https://btapi.izicenter.ir";
// export const API_BASE_IMG = "https://rs.izicenter.ir";

// Production Mode
export const API_BASE_URL = "https://admin.restook.api.atlon.ir";
export const API_BASE_IMG = "https://restook.api.atlon.ir";

// Dev Mode
// export const API_BASE_URL = "https://dev.admin.restook.api.atlon.ir";
// export const API_BASE_IMG = "https://restook.api.atlon.ir";
